﻿$(document).ready(function () {

    $('.newsletter-form').click(function () {
        $("#_nlSubmit").attr("disabled", false);
        $(".news-success,#txtEmail-error").hide();
        $("#txtEmail").val("");
        $("#newsletter-form .block2").show();
    });

    NewsNewsletter.ValidateNewsletterForm();
});

window["NewsNewsletter"] =
{
    Signup: function () {
        var valid = $("#newsletter-form").valid();

        if (valid) {
            $("#_nlSubmit").attr("disabled", true).attr("value", "Please Wait...");

            var params = new Object({
                Name: " ",
                Email: $("#txtEmail").val()
            });
            NewsNewsletter.AjaxCall(params);
        }
    },

    AjaxCall: function (params) {
        $.ajax({
            url: "/service/NewsletterSignup",
            method: "POST",
            headers: { "x-csrf-token": $("[name=__RequestVerificationToken]", "#AjaxAntiForgery").val() },
            data: params,
            success: function (data) {
                NewsNewsletter.DataCallback(data);
            },
            error: function () {
                alert("An error has occurred. Please try again.");
            }
        });
    },

    DataCallback: function (result) {
        if (result.success) {
            $("#newsletter-form .block2").hide();
            $(".news-success").show();
        } else {
            $("#_nlSubmit").attr("disabled", false).attr("value", "Subscribe");
        }
    },

    ValidateNewsletterForm: function () {

        $('#newsletter-form').validate({
            errorClass: 'invalid',
            rules: {
                email: {
                    required: true,
                    email: true
                }
            },
            messages: {
                email: {
                    required: "Email address is required",
                    email: "Enter a valid email address"
                }
            },
            highlight: function (element, errorClass) {
                $(element.form).find("label[for=" + element.id + "]")
                    .addClass(errorClass);
            },
            unhighlight: function (element, errorClass) {
                $(element.form).find("label[for=" + element.id + "]")
                    .removeClass(errorClass);
            }
        });
    }
};