﻿/** Add jquery polly fills here */
if (jQuery) {
    jQuery.fn.reverse = [].reverse;
}

window["ReloadArticlePage"] = function () {
    window.location.reload(true); // reload the current article page
}

window["PopulateVehicleDropdown"] = function (data, ddl) {
    $('#divLoading' + ddl).css("display", "none");
    $('#' + ddl + 'List').html(data).prop("disabled", false);
}

window["IsEmptyOrNaN"] = function (str) {
    return (!str || 0 === str.length || isNaN(str));
}

window["InsertNativeDeals"] = function () {
    $("img[data-deal-search-id],iframe[data-deal-search-id]").lazyLoadRequired(function(elem) {
        var imgOrIframeTag = $(elem);

        var instanceNumber = $(document).find(this).index(imgOrIframeTag) + 1;

        var nativeDealUrl = location.protocol + "//" + location.host + "/nativedeal/" + imgOrIframeTag.data("deal-search-id") + "/" + instanceNumber;

        $.ajax({
            async: true,
            url: nativeDealUrl
        }).done(function(partialView, textStatus) {
            imgOrIframeTag.parentsUntil("div.news-body").last().after(partialView);
        }).fail(function(jqXHR, textStatus, errorThrown) {
            console.log(textStatus);
            console.log(errorThrown);
        });
    });
}

window["getUrlVars"] = function () {
    var vars = [],
        hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
};